import React from "react";
import {connect} from "react-redux";

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';

import "mapbox-gl/dist/mapbox-gl.css";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

mapboxgl.accessToken = "pk.eyJ1IjoiZG5pbG9zZWsiLCJhIjoiY2trbjJtdXAxMXppZTJ1cTlxaGcxbHZiOSJ9.X0pA9XspNzXRYrFTkIwyog";

class Map extends React.Component {
    
    state = {
        basemap: null,
        dataset: null,
    }

    componentDidMount() {
        this.initMap();
    }

    initMap = callback => {
        this.setState(
            {
                basemap: new mapboxgl.Map({
                    container: this.mapContainer,
                    style: "mapbox://styles/mapbox/streets-v11",
                    center: [-90, 38],
                    zoom: 4,
                    maxZoom: 17,
                    minZoom: 4
                })
            },
            () => {
                this.state.basemap.on("load", () =>{
                    // Add controls
                    var geocoder = new MapboxGeocoder({
                        accessToken: mapboxgl.accessToken,
                        mapboxgl: mapboxgl
                    });
                    var nav = new mapboxgl.NavigationControl();
                    this.state.basemap.addControl(geocoder, "top-right");
                    this.state.basemap.addControl(nav, "top-right");
                    var draw = new MapboxDraw({
                        displayControlsDefault: false,
                        controls: {
                            polygon: true,
                            point: true,
                            trash: true,
                        }
                    });
                    this.state.basemap.addControl(draw, "top-right");
                    this.state.basemap.on('draw.create', this.annotate);
                    this.state.basemap.on('draw.delete', this.annotate);
                    this.state.basemap.on('draw.update', this.annotate);
                });
                this.state.basemap.once("idle", () =>{
                    this.state.basemap.addSource('mapbox-dem', {
                        'type': 'raster-dem',
                        'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
                        'tileSize': 512,
                        'maxzoom': 17
                        });
                    // add the DEM source as a terrain layer with exaggerated height
                    this.state.basemap.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });
                         
                    // add a sky layer that will show when the map is highly pitched
                    this.state.basemap.addLayer({
                        'id': 'sky',
                        'type': 'sky',
                        'paint': {
                        'sky-type': 'atmosphere',
                        'sky-atmosphere-sun': [0.0, 0.0],
                        'sky-atmosphere-sun-intensity': 15
                        }
                    });
                    // Insert the layer beneath any symbol layer.
                    var layers = this.state.basemap.getStyle().layers;
                    var labelLayerId;
                    for (var i = 0; i < layers.length; i++) {
                    if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
                    labelLayerId = layers[i].id;
                    break;
                    }
                    }
                    
                    // The 'building' layer in the Mapbox Streets
                    // vector tileset contains building height data
                    // from OpenStreetMap.
                    this.state.basemap.addLayer(
                    {
                    'id': 'add-3d-buildings',
                    'source': 'composite',
                    'source-layer': 'building',
                    'filter': ['==', 'extrude', 'true'],
                    'type': 'fill-extrusion',
                    'minzoom': 15,
                    'paint': {
                    'fill-extrusion-color': '#aaa',
                    
                    // Use an 'interpolate' expression to
                    // add a smooth transition effect to
                    // the buildings as the user zooms in.
                    'fill-extrusion-height': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    15,
                    0,
                    15.05,
                    ['get', 'height']
                    ],
                    'fill-extrusion-base': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    15,
                    0,
                    15.05,
                    ['get', 'min_height']
                    ],
                    'fill-extrusion-opacity': 0.6
                    }
                    },
                    
                    labelLayerId
                    );
                    this.addDataset(this.state.dataset);
                });
            }
        )
    }

    annotate(event){
        console.log(event)
    }
    
    addLayer(layer){
        console.log(layer)
        if(layer.type ==="raster"){
            if(!this.state.basemap.getSource(layer.layerID)){
                this.state.basemap.addSource(layer.layerID,{
                    type: "raster",
                    tiles: [layer.url],
                    tileSize: layer.tileSize,
                    scheme: "xyz"
                });
            }
            this.state.basemap.addLayer({
                id: layer.layerID,
                type: "raster",
                source: layer.layerID
            });

            const vis = layer.visible ? "visible" : "none";
            this.state.basemap.setLayoutProperty(layer.layerID, "visibility", vis);
        };
        if(layer.type === "vector"){
            this.state.basemap.addSource(layer.layerID, {
                type: layer.type,
                url: layer.url
            });
            this.state.basemap.addLayer({
                'id': layer.layerID,
                'type': "fill",
                'source': layer.layerID,
                'source-layer': layer.layer,
                'paint': {
                    'fill-color': layer.color,
                    'fill-opacity': 0.5
                }
            });
            const vis = layer.visible ? "visible" : "none";
            this.state.basemap.setLayoutProperty(layer.layerID, "visibility", vis);
        };
        if(layer.type === "geojson"){
            this.state.basemap.addSource(layer.layerID,{
                type: layer.type,
                data: layer.url
            });
            this.state.basemap.addLayer({
                id: layer.layerID,
                type: "fill",
                source: layer.layerID,
                paint: {
                    'fill-color': layer.color,
                    'fill-opacity': 0.5
                }
            });
            const vis = layer.visible ? "visible" : "none";
            this.state.basemap.setLayoutProperty(layer.layerID, "visibility", vis);
        };
    }

    isReady(){
        if(!(this.state.basemap === null)){
            if(this.state.basemap.isStyleLoaded()){
                return true;
            }
        }
        return false;
    }

    waitFor(cond, callback){
        if(!cond()){
            window.setTimeout(this.waitFor.bind(null, cond, callback), 100);
        } else {
            callback();
        }
    }

    center(dataset){
        this.state.basemap.flyTo({center: [dataset.center.lon, dataset.center.lat], zoom: dataset.center.zoom})
    }

    addDataset(dataset){
        console.log("Adding Dataset")
        console.log(dataset)
        if(dataset){
            // Add layers
            dataset.layers.forEach(layer=>{
                this.addLayer(layer);
            })
        }
    }

    clearCurrentDataset(idsToClear){
        console.log("Clearing current dataset")
        idsToClear.forEach(id =>{
            this.state.basemap.removeSource(id)
            this.state.basemap.removeLayer(id)
        })
    }

    updateVisbility(dataset){
        dataset.layers.forEach(layer =>{
            var vis = layer.visible ? "visible" : "none";
            this.state.basemap.setLayoutProperty(layer.layerID, "visibility", vis);
        })
    }

    /**
     * Update to a new dataset
     * @param {object} dataset 
     */
    updateDataset(){
        console.log("Updating dataset");
        if(this.props.shouldUpdateDataset){
            console.log("New dataset")
            this.waitFor(() => this.isReady, () => this.clearCurrentDataset(this.props.idsToClear));
            this.waitFor(() => this.isReady, () => this.addDataset(this.props.dataset));
        };
        if(this.props.shouldCenterDataset){
            console.log("Should center");
            this.waitFor(() => this.isReady, () => this.center(this.props.dataset));
        };
        if(this.props.shouldUpdateVis){
            console.log("Update visibility");
            this.waitFor(() => this.isReady, () => this.updateVisbility(this.props.dataset));
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        console.log("Should update")
        if(nextProps.dataset){
            return true
        }
        return false
    }

    componentDidUpdate(){
        this.updateDataset()
    }

    render() {
        return (
            <div ref={el => (this.mapContainer = el)} style={{width: 'inherit', height: 'inherit'}} />
        )
    }
}

const mapStateToProps = state => {
    return {
        dataset: state.activeDataset,
        shouldUpdateDataset: state.shouldUpdateDataset,
        shouldCenterDataset: state.shouldCenterDataset,
        shouldUpdateVis: state.shouldUpdateVis,
        idsToClear: state.idsToClear
    }
}

export default connect(mapStateToProps)(Map);