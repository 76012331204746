import React from "react";
import { connect } from "react-redux";

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typeography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

import { setActiveDataset } from '../actions';

const styles = (theme) => ({
    root: {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        width: "100%"
    },
    title: {
        flexGrow: 1,
        textAlign: "center",
    },
    content: {
        flexGrow: 1,
        textAlign: "center"
    }
})

// Object to handle the dataset list
class DatasetList extends React.Component {

    renderDatasetList(){
        const {classes} = this.props;
        return this.props.datasets.map(dataset => {
            return (
                <ListItem key={dataset.title} dense button onClick={() => this.props.setActiveDataset(dataset)}>
                    <Card className={classes.root}>
                        <CardHeader 
                            title={dataset.title}
                            className={classes.title}
                        />
                        <Hidden xsDown implementation="css">
                            <CardContent className={classes.content}>
                                <Typeography variant="caption" component="p">
                                {dataset.description}
                                </Typeography>
                            </CardContent>
                        </Hidden>
                    </Card>
                </ListItem>
            )
        });
    }

    render(){
        return (
            <List>
                {this.renderDatasetList()}
            </List>
        );
    }
}

const mapStateToProps = state => {
    return {
        datasets: state.datasets
    }
}


export default connect(mapStateToProps, {setActiveDataset})(withStyles(styles)(DatasetList));