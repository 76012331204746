import React from 'react';
import { connect } from "react-redux";
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './theme';
import './App.css';
import { updateDatasets } from '../actions';
import Api from '../api';
import Dashboard from './Dashboard';

// Object to handle the dataset list
class App extends React.Component {
  
  constructor(props) {
    super(props)
    this.getDatasets()
  }

  getDatasets(){
    Api.get('/datasets')
    .then(res => {
      var ds = res.data;
      ds.forEach(dataset=>{
         dataset.layers = dataset.layers.map(layer =>{
          return {
            ...layer,
            visible: true
          };
         });
      });
      this.props.updateDatasets(ds);
    })
  }

  render(){
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Dashboard />
        </ThemeProvider>
      </div>
    );
  }
}

export default connect(null, {updateDatasets})(App);