/**
 * Update datasets
 * @param {object} datasets a datasets object
 */
export const updateDatasets = datasets => {
    return {
        type: "UPDATE_DATASETS",
        payload: datasets
    };
};

/**
 * 
 * Set active dataset
 * @param {object} dataset a dataset object
 */
export const setActiveDataset = dataset => {
    return {
        type: "SET_ACTIVE_DATASET",
        payload: dataset
    }
}

export const toggleVisible = id => {
    return {
        type: "TOGGLE_VISIBLE",
        payload: id
    }
}