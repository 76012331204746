import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabPanel from '@material-ui/lab/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Map from './Map';
import DatasetList from './DatasetList';
import LayerList from './LayerList';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Hidden from '@material-ui/core/Hidden';
import Typeography from '@material-ui/core/Typography';

import hyspec_logo from './hyspec_logo.png';

const drawerWidth = 360;

const useStyles = makeStyles((theme) =>({
  root: {
      display: 'flex',
  },
  appBar: {
      zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
  },
  menuButton: {
      marginRight: theme.spacing(2),
  },
  closeMenuButton: {
      marginRight: 'auto',
      marginLefT: 0,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
      width: drawerWidth,
  },
  content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
  },
  appBarSpacer: theme.mixins.toolbar,
  logo: {
      maxWidth: 160,
  },
  title: {
      flexGrow: 1,
      [theme.breakpoints.up('lg')]:{
        textAlign: "center",
      },
      [theme.breakpoints.down('md')]:{
        textAlign: "right",
      },
  },
}));


export default function Dashboard() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState("1");
  const [mobileOpen, setMobileOpen] = React.useState(false);

  function handleDrawerToggle(){
      setMobileOpen(!mobileOpen)
  }

  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  const drawerContent = (
      <div>
        <TabContext value={value} >
            <Tabs
              indicatorColor="primary"
              variant="scrollable"
              aria-label="full width tabs"
              onChange={handleChange}
              value={value}
            >
                <Tab label="Datasets" value="1"/>
                <Tab label="Layers" value="2"/>
                <Tab label="Annotations" value="3" />
            </Tabs>
            <TabPanel value="1">
                <DatasetList />
            </TabPanel>
            <TabPanel value="2">
                <LayerList />
            </TabPanel>
            <TabPanel value="3">
            </TabPanel>
        </TabContext>
      </div>
  );

  return(
    <main className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                <IconButton color="inherit" aria-label="Open drawer" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
                    <MenuIcon />
                </IconButton>
                <Typeography className={classes.title}>
                    <img src={hyspec_logo} alt="logo" className={classes.logo} />
                </Typeography>
            </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
                <Drawer variant="temporary" 
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        modelprops={{
                            keepMounted: true,
                        }}
                >
                    <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
                        <CloseIcon />
                    </IconButton>
                    {drawerContent}
                </Drawer>
        </nav>
        <div className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Map/>
        </div>
    </main>
  );
};

Dashboard.defaultProps = {
    value: 1
}