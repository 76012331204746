// The inital state for the app
const initialState = {
    datasets: [],
    activeDataset: null,
    shouldUpdateDataset: false,
    shouldCenterDataset: false,
    shouldUpdateVis: false,
    idsToClear: []
}

/** Reducer function to handle state change
 * 
 *  @param {object} state The current state
 *  @param {object} action The action with the type and payload
 */
function rootReducer(state = initialState, action){
    switch(action.type){
        case "UPDATE_DATASETS":
            return {
                ...state,
                datasets: action.payload
            }
        case "SET_ACTIVE_DATASET":
            var idsToClear = [];
            if(state.activeDataset){
                if(state.activeDataset.id === action.payload.id) {
                    // Do nothing and return state
                    console.log("Same dataset")
                    return {
                        ...state,
                        shouldUpdateDataset: false,
                        shouldCenterDataset: true,
                        shouldUpdateVis: false,
                    };
                }
                idsToClear = state.activeDataset.layers.map(layer => {
                    return layer.layerID
                })
            }
            return {
                ...state,
                idsToClear: idsToClear,
                activeDataset: action.payload,
                shouldUpdateDataset: true,
                shouldCenterDataset: true,
                shouldUpdateVis: false,
            }
        case "TOGGLE_VISIBLE":
            return {
                ...state,
                shouldUpdateDataset: false,
                shouldCenterDataset: false,
                shouldUpdateVis: true,
                activeDataset: {
                    ...state.activeDataset,
                    layers: state.activeDataset.layers.map(layer => {
                        if(layer.layerID === action.payload){
                            const vis = layer.visible
                            return{
                                ...layer,
                                visible: !vis
                            };
                        }
                        return layer;
                    })
                }
            }
            
        default:
            return state
    }
}

export default rootReducer;