import { createMuiTheme } from '@material-ui/core/styles';
import { black } from '@material-ui/core/colors';

export const theme = createMuiTheme({
  palette: {
      primary: {
          main: "#000000",
      },
      secondary: {
          main: '#ffffff',
      }
  }
});