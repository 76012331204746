import React from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { toggleVisible } from '../actions';
import LayerItem from './LayerItem';

const styles = (theme) => ({
    root: {
        width: '100%',
    },
    divider: {
        marginTop: '25px',
        marginBottom: '25px',
    }
})

class LayerList extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            checkedIds: []
        }
    }

    handleCheck(id) {
        this.setState(state=>({
            checkedIds: state.checkedIds.includes(id)
            ? state.checkedIds.filter(c => c !== id)
            : [...state.checkedIds, id]
        }));
        this.props.toggleVisible(id);
    }

    renderLayerList(type){
        if(this.props.dataset){
           return this.props.dataset.layers.filter(function(layer){
               return layer.type === type;
           }).map(layer => {
               return(
                  <LayerItem layer={layer} handleCheck={ () => this.handleCheck(layer.layerID)}/>
               )
            });
       }
    }

    render(){
        const {classes} = this.props;
        return(
            <div>
                <Typography align="center" className={classes.root}>Raster Layers</Typography>
                <List className={classes.root}>
                    {this.renderLayerList("raster")}
                </List>
                <Divider className={classes.divider}/>
                <Typography align="center" className={classes.root}>Vector Layers</Typography>
                <List className={classes.root}>
                    {this.renderLayerList("vector")}
                    {this.renderLayerList("geojson")}
                </List>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        dataset: state.activeDataset
    }
}


export default connect(mapStateToProps, {toggleVisible})(withStyles(styles)(LayerList));