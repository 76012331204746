import React from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import GetAppIcon from '@material-ui/icons/GetApp';


const useStyles = makeStyles((theme) => ({
    listItem:{
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        borderRadius: 15,
        margin: '5px 0',
    },
    dragIcon:{
        color: theme.palette.primary.contrastText,
    },
    checkbox:{
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
    },
    typopgraphy: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.contrastText,
    }
}));


export default function LayerItem(props){
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
                <ListItem key={props.layer.layerID} dense role={undefined} className={classes.listItem}>
                    <ListItemIcon>
                        <Checkbox 
                        edge="start"
                        onChange={() => props.handleCheck()}
                        checked={props.layer.visible}
                        className={classes.checkbox}
                        />
                    </ListItemIcon>
                    <ListItemText id={props.layer.layerID} primary={props.layer.name} />
                    <IconButton edge="start" aria-label="drag" className={classes.dragIcon} onClick={handleClick}>
                          <GetAppIcon />
                    </IconButton>
                    <Popover
                        id={props.layer.layerID}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            hertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                      <Typography className={classes.typography}>{props.layer.url}</Typography>
                    </Popover>
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="drag" className={classes.dragIcon}>
                          <DragHandleIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>            
        </div>
    )

}